<template>
  <div>
    <div  id="save">
      <headerEl />
      <main id="wrap" class="has-width-limit">
        <div style="margin: 40px; font-size: 0.5rem">
          予約完了画面にリダイレクトしています。
        </div>
      </main>
      <footerEl />
    </div>
    <!-- <div v-if="false" id="complete">
      <headerEl />
      <main id="wrap" class="has-width-limit">
        <div id="user-information-complete-vue">
          <div class="frame-block">
            <header class="frame-block-head">
              <h1>
                無料カウンセリング<br />
                予約完了いたしました
              </h1>
            </header>
            <div class="frame-block-body">
              <p>
                ご登録いただいたメールアドレスに予約完了メールを<br />
                お送りしますので、ご確認ください。<br />
                ご来院をお待ちしております。
              </p>
            </div>
            <footer class="frame-block-foot">
              <a @click="urltoWeb" class="large-button red" target="_blank">
                アリシアクリニックWebサイトへ
              </a>
            </footer>
          </div>
        </div>
      </main>
      <footerEl />
    </div> -->
  </div>
</template>

<script>
import headerEl from "@/components/header.vue";
import footerEl from "@/components/footer.vue";
import { mapState } from "vuex";
import { indexApi } from "@/api/module.js";
import $ from "jquery";

export default {
  data() {
    return {
      mapShow: false,
      careShow: false,
      infoShow: false,
      mapUrl:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.3167633188623!2d139.69573031562817!3d35.69382188019142!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188cd6447180d5%3A0x343cbf22f9509a7f!2z44Ki44Oq44K344Ki44Kv44Oq44OL44OD44Kv5paw5a6_6KW_5Y-j6Zmi!5e0!3m2!1sja!2sjp!4v1567474621066!5m2!1sja!2sjp",
    };
  },
  components: { headerEl, footerEl },
  computed: {
    ...mapState([
      "completeInfo",
      "userInfo",
      "completeRes",
      "loginError",
      "jsTag",
      "xuid",
      "suid",
    ]),
  },
  mounted() {
    // indexApi
    //   .complete({
    //     ...this.completeInfo,
    //     ...this.userInfo,
    //   })
    //   .then((res) => {
    //     if (res.code == 200) {
    //       this.$store.commit("updateCompleteRes", res.data);

          let routeData = this.$router.resolve({
            path: "/Reservations/complete",
            query: {
              id: this.completeRes.customerId,
              xuid: this.xuid,
              buid: this.completeRes.reserveCode,
              suid: this.suid,
            },
          });
          window.location.replace(routeData.href)
      //     this.errMsg = "";
      //   } else {
      //     // this.showError(res.msg);
      //     this.errMsg = res.msg;
      //   }
      // });
  },
  methods: {

    urltoWeb() {
      window.open(
        "https://www.aletheia-clinic.com/"
      );
    },
  },
};
</script>
